import React from "react"
import logo from "../assets/fuelbar15_logo.png"
import { MdMenu, MdClose } from "react-icons/md"
import { Link } from "gatsby"
import PageLinks from "../constants/links"
import SocialLinks from "../constants/socialLinks"
import "./Navbar.css"
const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <div className="navbar-fixed">
        <nav>
          <div className="nav-wrapper white ">
            <Link to="/" className="brand-logo brown-text">
              <img src={logo} alt="logo" className="logo" />
            </Link>
            <button
              className="toggle-btn hide-on-large-only valign-wrapper"
              type="button"
              onClick={toggleSidebar}
            >
              <MdMenu></MdMenu>
            </button>
            <ul className="right hide-on-med-and-down nav-links valign-wrapper">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/recipes">Recipes</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <aside className={`sidebar ${isOpen ? "show-sidebar" : ""} `}>
        <button className="close-btn" onClick={toggleSidebar}>
          <MdClose></MdClose>
        </button>
        <div className="side-container">
          <ul className={`invisable ${isOpen ? "sidebar-links" : ""}`}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/recipes">Recipes</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <SocialLinks styleClass={`${isOpen ? "sidebar-icons" : ""}`} />
        </div>
      </aside>
    </>
  )
}

export default Navbar
