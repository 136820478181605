import React from "react"
import SocialLinks from "../constants/socialLinks"
import "./Footer.css"
const Footer = () => {
  return (
    <footer className="footer">
      <div className="">
        <SocialLinks styleClass="footer-links" />
        <h4>
          copyright &copy; {new Date().getFullYear()} <span> </span>
          <span>Fuel Bar 15</span> - All Rights Reserved
        </h4>
      </div>
    </footer>
  )
}

export default Footer
